// imports
import { CLOSED_CAPTIONING_SUPPORT_URL } from '@/lib/constants';

// styles
import styles from './VideoHelp.module.scss';
interface VideoHelpProps {
  className?: string;
}
const VideoHelp = (props: VideoHelpProps) => {
  const {
    className
  } = props;
  // @TODO add "report a problem" modal
  return <p className={`${className} ${styles.video_help}`} data-sentry-component="VideoHelp" data-sentry-source-file="VideoHelp.tsx">
      Problems with Closed Captions?&nbsp;
      <a href={CLOSED_CAPTIONING_SUPPORT_URL} target="_blank">Closed Captioning Feedback</a>
    </p>;
};
export default VideoHelp;